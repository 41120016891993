import React from 'react';
import S from '../../styled';

export default function Metric(props) {
  const { value, name, clickable, hoverable, onClick } = props;

  return (
    <S.Flex
      clickable={clickable}
      hoverable={hoverable}
      column
      alignCenter
      justifyCenter
      onClick={onClick}
    >
      <S.Text uppercase>
        {value}
      </S.Text>
      <S.Text whiteSpaceNoWrap uppercase lightWeight>
        {name}
      </S.Text>
    </S.Flex>
  );
}
