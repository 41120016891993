import React, { useState, useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

// Styled components
import S from '../../styled';

// Model Requests
import { getCampaignPrograms } from '../../data/requests/program';

// Routes
import { routeNames } from '../../routing/routes';

// Helpers
import navigateTo from '../../helpers/navigateTo';

// Components
import Module from '../../components/Module';
import CampaignProgramPreview from './CampaignProgramPreview';

export default function CampaignProgramModule(props) {
  const { store, title, limit, available, clear, dashboardView } = props;
  const { token, t } = store;
  const history = useHistory();

  const [programs, setPrograms] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isEmpty, setIsEmpty] = useState(false);
  const [isMounted, setIsMounted] = useState(true);
  const [hasError, setHasError] = useState(false);

  const fetchPrograms = useCallback(async () => {
    if (!token) return;

    setIsLoading(true);
    setIsEmpty(false);
    setHasError(false);
    try {
      const params = { limit: limit || null, available: available }
      const programs = await getCampaignPrograms(token, params);
      if (!programs || !programs.length) setIsEmpty(true);
      setPrograms(programs);
    } catch (error) {
      setHasError(true);
      setIsEmpty(true);
      console.error('fetchPrograms', error);
    } finally {
      if (isMounted) setIsLoading(false);
    }
  }, [token, isMounted, limit, available]);

  useEffect(() => {
    fetchPrograms();
    return () => setIsMounted(false);
  }, [fetchPrograms]);

  const navigateToAllCampaigns = (e) => {
    e.preventDefault();
    navigateTo(history, routeNames.campaignPrograms, token)
  };

  const titleBlock = (
    <S.Link clickable={dashboardView} hoverable={dashboardView} onClick={navigateToAllCampaigns}>
      <S.Text>
        {title}
      </S.Text>
    </S.Link>
  );

  const topRightContent = (
    <S.Flex row alignEnd justifyEnd flex={1}>
      <S.Link
        href={'#'}
        muted
        hoverable
        clickable
        fontSize={14}
        flex={0}
        onClick={navigateToAllCampaigns}
      >
        {t('components.CampaignProgramsModule.viewAllAvailableCampaigns')}
      </S.Link>
    </S.Flex>
  );

  const renderEmptyContent = () => {
    const suffix = available ? t('components.CampaignProgramsModule.suffixForSignUp') : '';
    const emptyContextText = hasError 
      ? t('components.CampaignProgramsModule.errorLoadingCampaign') 
      : t('components.CampaignProgramsModule.noCampaignsAvailable', { suffix });
    return (<S.Text maxWidth textAlignCenter muted paddingTop={40} paddingBottom={40}>{emptyContextText}</S.Text>);
  };

  const renderPrograms = () => {
    if (!programs || !programs.length) return;
    return programs.map((program, idx) => (
      <CampaignProgramPreview program={program} store={store} key={`program-${idx}`}/>
    ))
  };

  return (
    <Module
      isLoading={isLoading}
      isEmpty={isEmpty}
      emptyContent={renderEmptyContent()}
      clear={clear}
      titleBlock={title && titleBlock}
      topRightContent={dashboardView ? topRightContent : null}
    >
      <S.Flex column gap={10} marginTop={20}>
        {renderPrograms()}
      </S.Flex>
    </Module>
  );
}
