import React, { useEffect, useState, useCallback } from 'react';
import numeral from 'numeral';
import getSymbolFromCurrency from 'currency-symbol-map';
import S from '../../styled';
import { formatCurrency } from '../../helpers/formatters';

// Components
import Module from '../Module';
import MetricRow from '../MetricRow';

// Requests
import { getPerformance } from '../../data/requests/performance';

export default function PaymentsModule(props) {
  const { token, t } = props.store;

  const [isMounted, setIsMounted] = useState(true);
  const [moduleData, setModuleData] = useState({});
  const [moduleIsLoading, setModuleIsLoading] = useState(true);

  const loadModuleData = useCallback(async () => {
    if (!token) return;

    try {
      setModuleIsLoading(true);
      const data = await getPerformance(token);
      setModuleData(data);
    } catch (error) {
      console.error('loadModuleData: ', error);
    } finally {
      if (isMounted) setModuleIsLoading(false);
    }
  }, [token, isMounted]);

  useEffect(() => {
    loadModuleData();
    return () => setIsMounted(false);
  }, [loadModuleData]);

  const revenueCurrencySymbol = getSymbolFromCurrency(moduleData.revenue_currency || 'USD');
  const payments = numeral(moduleData.payments || 0);
  const commissions = numeral(moduleData.commissions || 0);
  const earnings = numeral(moduleData.total_earnings || 0);

  const formattedPayments = formatCurrency(revenueCurrencySymbol, payments);
  const formattedCommissions = formatCurrency(revenueCurrencySymbol, commissions);
  const formattedEarnings = formatCurrency(revenueCurrencySymbol, earnings);

  const isEmpty = numeral => !numeral || !numeral.value();

  if (isEmpty(payments) && isEmpty(commissions)) return null;
  let metrics = [];

  if (!isEmpty(payments) && !isEmpty(commissions)) {
    metrics = [
      { largeSize: true, hoverable: true, name: t('components.PaymentsModule.commissions'), value: formattedCommissions },
      { placeholder: true, name: "+" },
      { largeSize: true, hoverable: true, name: t('components.PaymentsModule.payments'), value: formattedPayments },
      { placeholder: true, name: "=" },
      { largeSize: true, hoverable: true, name: t('components.PaymentsModule.earnings'), value: formattedEarnings },  
    ]
  } else {
    if (!isEmpty(commissions)) {
      metrics.push({ largeSize: true, hoverable: true, name: t('components.PaymentsModule.commissions'), value: formattedCommissions });
    } else {
      metrics.push({ largeSize: true, hoverable: true, name: t('components.PaymentsModule.payments'), value: formattedPayments });
    }
  }

  return (
    <Module
      title={t('components.PaymentsModule.title')}
      subtitle={t('components.PaymentsModule.subtitle')}
      isLoading={moduleIsLoading}
      flex={props.flex}
      minHeight={150}
    >
      <S.Visibility mobile width='100%' backgroundColor="red">
        <MetricRow metrics={metrics} between={true}/>
      </S.Visibility>
      <S.Visibility tablet desktop width='100%' backgroundColor="red">
        <MetricRow metrics={metrics} center={true}/>
      </S.Visibility>
    </Module>
  );
}
